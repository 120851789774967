import React, { useContext } from 'react'
import Helmet from 'react-helmet'
import {injectIntl} from 'react-intl'
import { ControlledEditor } from '@monaco-editor/react'

import Dropzone from 'react-dropzone'
import { ParserContext } from '../context/parser'
import '../util/monacoSkript'

import '../static/parser.scss'
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPlus, faFile, faPlay, faEllipsisV, faSave } from '@fortawesome/free-solid-svg-icons'


const Parser = (props) => {
  const context = useContext(ParserContext)

  const translate = (id) => {
    return props.intl.messages[id]
  }

  //onClick on filebar element
  const fileBarHandle = (ev) => {
    let target = ev.target.getAttribute('for')
    if(!target) target = ev.target.parentNode.getAttribute('for')
    if(!document.querySelector("#filebar").classList.contains("active")){
      document.querySelector("#filebar").classList.add("active")
      document.querySelector("#mobileFilebar").classList.add("active")
    }
    document.querySelectorAll('#filebar ul, #mobileFilebar ul').forEach((element) => {
      element.classList.remove('active')
    })
    document.getElementById(target).classList.toggle("active")
  }

  //onMouseOver on filebar element
  const fileBarHandleHover = (ev) => {
    let target = ev.target.getAttribute('for')
    if(document.querySelector("#filebar").classList.contains("active")){
      document.querySelectorAll('#filebar ul').forEach((element) => {
        element.classList.remove('active')
      })
      if(target) document.getElementById(target).classList.toggle("active")
    }
  }

  //What's new? Modal
  const whatsnew = () => {
    Swal.fire({
      title: "What's new?",
      text: "Literally the whole parser",
      showCloseButton: true,
      showConfirmButton: false,
      focusClose: false,
    })
  }

  //Close Filebar if clicked outside of it
  const fileBarOff = (ev) => {
    if(!ev.target.getAttribute('for') && document.querySelector('#editor')){
      document.querySelector('#filebar').classList.remove('active')
      document.querySelector('#mobileFilebar').classList.remove('active')
      document.querySelectorAll('#filebar ul').forEach((element) => {
        element.classList.remove('active')
      })
      document.querySelectorAll('#mobileFilebar ul').forEach((element) => {
        element.classList.remove('active')
      })
    }
  }

  return (
    <div id="parser" className="flex flex-col h-screen bg-skugray-900 overflow-hidden md:overflow-auto" onClick={fileBarOff}>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content="skUnity Parser" />
      </Helmet>
      <div id="mobileFilebar" className="flex flex-row px-8 md:hidden">
        <div className="relative flex-1 text-skugray-400 text-lg">
          <div className="py-2 px-6 text-center"><FontAwesomeIcon icon={faFile}/></div>
        </div>
        <div className="relative flex-1 text-skugray-400 text-lg">
          <div className="py-2 px-6 text-center"><FontAwesomeIcon icon={faSave}/></div>
        </div>
        <div className="relative flex-1 text-skugray-400 text-lg">
          <div className="py-2 px-6 text-center" onClick={context.parse}><FontAwesomeIcon icon={faPlay}/></div>
        </div>
        <div className="relative flex-1 text-skugray-400 text-lg" htmlFor="mobileMore">
          <div className="py-2 px-6 text-center" onClick={fileBarHandle} htmlFor="mobileMore"><FontAwesomeIcon htmlFor="mobileMore" icon={faEllipsisV}/></div>
          <ul id="mobileMore" className="absolute z-50 bg-black right-0 w-auto text-right">
            <li className="py-2 hover:bg-skugray-800 px-4" onClick={whatsnew}>{translate('parser.filebar.news')}</li>
            <li className="py-2 hover:bg-skugray-800 px-4">{translate('parser.filebar.help.about')}</li>
          </ul>
        </div>
      </div>
      <div id="filebar" className="flex-row px-16 hidden md:flex">
        <div className="relative flex-initial cursor-pointer text-white text-sm hover:bg-black">
          <div className="py-2 px-8" htmlFor="file" onMouseEnter={fileBarHandleHover} onClick={fileBarHandle}>{translate('parser.filebar.file')}</div>
          <ul id="file" className="absolute z-50 bg-black left-0 w-64">
            <li className="py-2 hover:bg-skugray-800 px-4" onClick={context.createModel}>{translate('parser.filebar.file.new')}</li>
            <Dropzone onDrop={context.handleDrop}>
              {({getRootProps, getInputProps}) => (
                <li className="py-2 hover:bg-skugray-800 px-4" {...getRootProps()}>
                  <input {...getInputProps()} />
                  {translate('parser.filebar.file.open')}
                </li>
              )}
            </Dropzone>
            <li className="py-2 hover:bg-skugray-800 px-4">{translate('parser.filebar.file.load')}</li>
            <li className="py-2 hover:bg-skugray-800 px-4">{translate('parser.filebar.file.save')}</li>
          </ul>
        </div>
        <div className="relative flex-initial cursor-pointer text-white text-sm hover:bg-black">
          <div className="py-2 px-8" htmlFor="edit" onMouseEnter={fileBarHandleHover} onClick={fileBarHandle}>{translate('parser.filebar.edit')}</div>
          <ul id="edit" className="absolute z-50 bg-black left-0 w-64">
            <li className="py-2 hover:bg-skugray-800 px-4">{translate('parser.filebar.edit.undo')}</li>
            <li className="py-2 hover:bg-skugray-800 px-4">{translate('parser.filebar.edit.redo')}</li>
            <li className="py-2 hover:bg-skugray-800 px-4">{translate('parser.filebar.edit.cut')}</li>
            <li className="py-2 hover:bg-skugray-800 px-4">{translate('parser.filebar.edit.copy')}</li>
            <li className="py-2 hover:bg-skugray-800 px-4">{translate('parser.filebar.edit.paste')}</li>
          </ul>
        </div>
        <div className="relative flex-initial cursor-pointer text-white py-2 px-8 text-sm hover:bg-black" onMouseEnter={fileBarHandleHover}>{translate('parser.filebar.insert')}</div>
        <div className="relative flex-initial cursor-pointer text-white py-2 px-8 text-sm hover:bg-black" onMouseEnter={fileBarHandleHover}>{translate('parser.filebar.parse')}</div>
        <div className="relative flex-initial cursor-pointer text-white text-sm hover:bg-black">
          <div className="py-2 px-8" htmlFor="preferences" onMouseEnter={fileBarHandleHover} onClick={fileBarHandle}>{translate('parser.filebar.preferences')}</div>
          <ul id="preferences" className="absolute z-50 bg-black left-0 w-64">
            <li className="py-2 hover:bg-skugray-800 px-4">{translate('parser.filebar.preferences.settings')}</li>
            <li className="py-2 hover:bg-skugray-800 px-4">{translate('parser.filebar.preferences.theme')}</li>
          </ul>
        </div>
        <div className="relative flex-initial cursor-pointer text-white text-sm hover:bg-black">
          <div className="py-2 px-8" htmlFor="help" onMouseEnter={fileBarHandleHover} onClick={fileBarHandle}>{translate('parser.filebar.help')}</div>
          <ul id="help" className="absolute z-50 bg-black left-0 w-64">
            <li className="py-2 hover:bg-skugray-800 px-4">{translate('parser.filebar.help.about')}</li>
          </ul>
        </div>
        <div className="relative flex-initial cursor-pointer text-white py-2 px-8 text-sm hover:bg-black" onMouseEnter={fileBarHandleHover} onClick={whatsnew}>{translate('parser.filebar.news')}</div>
      </div>
      <div id="ide" className="flex-1 flex flex-row">
        <div id="editor" className="flex-1 flex flex-col">
          <Dropzone onDrop={context.handleDrop}>
            {({getRootProps}) => (
            <div {...getRootProps()}>
              <ControlledEditor language="Skript" theme="skUnity" editorDidMount={context.handleEditorDidMount} onChange={context.handleEditorChange} />
            </div>
            )}
          </Dropzone>
          <div id ="files" className="flex-1 flex flex-row">
            { context.models.map((model) => (
                <div key={model.id} className={ context.models.length === 1 && "active cursor-pointer text-white text-xs py-2 border-t-2 border-skured-500 uppercase font-bold rounded-t-lg"
                || context.editorInstance.current.getModel().uri === model.uri ? "active cursor-pointer text-white text-xs py-2 border-t-2 border-skured-500 uppercase font-bold rounded-t-lg" : "cursor-pointer text-white text-xs py-2 border-t-2 border-skured-500 uppercase font-bold rounded-t-lg"}>
                  <span className="px-8" onDoubleClick={context.renameModel} onClick={()=>context.setModel(model.uri)} spellCheck="false">{model.uri}</span>
                  <span className="cursor-pointer w-4 p-2 hover:bg-skugray-800 rounded-tr-lg" onClick={()=>context.closeModel(model.uri)}><FontAwesomeIcon icon={faTimes} /></span>
                </div>
              )) }
            <div onClick={context.createModel} className="cursor-pointer text-white text-xs py-2 px-3 border-t-2 border-skured-500 uppercase font-bold rounded-t-lg">
              <FontAwesomeIcon className="text-xs" icon={faPlus} />
            </div>
          </div>
        </div>
        <div id="debug" className="bg-black flex-none text-white lg:w-4/12 p-8 hidden md:block" style={{background: "#0f0f0f"}}>
          {Array.isArray(context.fileState.errors) ? context.fileState.errors.map((parsed)=>(
            <p key={parsed.lineNumber}><a className="text-skured-500 hover:text-skured-900" href="#" htmlFor={parsed.lineNumber} onClick={context.highlightLine}>Line {parsed.lineNumber}:</a> {parsed.line}</p>
          )) : <p>{context.fileState.errors}</p>}
        </div>
      </div>
      <div id="stats" style={{background: "#0f0f0f"}}>
        <div className="container mx-auto grid grid-cols-2 sm:grid-cols-6 gap-2 py-5">
          <div className="font-bold text-white text-sm uppercase text-center my-auto flex-grow">
            {translate('parser.statusbar.status')}: <span className="text-green-500">{context.fileState.status}</span>
          </div>
          <div className="font-bold text-skured-500 text-sm uppercase text-center my-auto flex-grow">
            <span className="text-white">{Array.isArray(context.fileState.errors) ? context.fileState.errors.length : 0}</span> {translate('parser.statusbar.errors')}
          </div>
          <div className="font-bold text-teal-500 text-sm uppercase text-center my-auto flex-grow hidden md:block">
            <span className="text-white">{context.fileState.commands}</span> {translate('parser.statusbar.commands')}
          </div>
          <div className="font-bold text-green-500 text-sm uppercase text-center my-auto flex-grow hidden md:block">
            <span className="text-white">{context.fileState.functions}</span> {translate('parser.statusbar.functions')}
          </div>
          <div className="font-bold text-purple-600 text-sm uppercase text-center my-auto flex-grow hidden md:block">
            <span className="text-white">{context.fileState.variables}</span> {translate('parser.statusbar.variables')}
          </div>
          <div className="font-bold text-skugray-400 text-sm uppercase text-center my-auto flex-grow cursor-pointer hidden md:block" onClick={context.parse}>
            Click to Parse
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(Parser)