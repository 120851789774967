import React, { useReducer, createContext } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import _ from 'lodash'

import { query } from '../util/gql'

const initialState = {
  docs: null
}

if(localStorage.getItem('docs')){
  initialState.docs = JSON.parse(localStorage.getItem('docs'))
}

const DocsContext = new createContext({
  docs: null,
  getDocs: () => {}
})

const docsReducer = (state, action) => {
  switch(action.type){
    case 'SET':
      return {
        ...state,
        docs: action.payload
      }
    default:
      return state
  }
}

const DocsProvider = (props) => {
  const [state, dispatch] = useReducer(docsReducer, initialState)
  const [getDocs] = useLazyQuery(query.getAllDocs, {
    onCompleted: (data) => {
      setDocs(data)
    },
    onError: (err) => {
      console.log(err)
    }
  })

  const setDocs = (docsData) => {
    localStorage.setItem('docs', JSON.stringify(_.sortBy(docsData, ['id'])))
    dispatch({
      type: 'SET',
      payload: docsData
    })
  }

  return (
    <DocsContext.Provider
      value={{ docs: state.docs ? state.docs.getAllDocs : null, getDocs }}
      {...props}
      />
  )
}

export { DocsContext, DocsProvider }