import React, { useContext } from 'react'
import Helmet from 'react-helmet'
import { injectIntl } from 'react-intl'
import {  useQuery } from "@apollo/react-hooks"
import { Link } from 'react-router-dom'
import _ from 'lodash'

import { AuthContext } from '../../context/auth'
import ManageSideBar from '../../components/Manage/SideNav'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight, faSpinner } from '@fortawesome/free-solid-svg-icons'

import { query } from '../../util/gql'

const Manage = (props) => {
  const { user } = useContext(AuthContext)
  const translate = (id) => {
    return props.intl.messages[id]
  }

  const { data: myAddonsData, loading: myAddonsLoading, error: myAddonsError} = useQuery(query.getMyAddons)
  //const { data: myParsesData, loading: myParsesLoading, error: myParsesError } = useQuery(MY_PARSES)

  let myAddons = {}
  if(myAddonsData){
    let dataSorted = _.sortBy(myAddonsData.getMyAddons, [addon => addon.name.toLowerCase()])
    myAddons = _.chunk(dataSorted,5)[0]
  }

  return (
    <div id="Manage" className="flex-1">
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content="skUnity Me" />
      </Helmet>
      <div className="flex h-full">
        <ManageSideBar/>
        <div className="flex-1 overflow-y-auto">
          <div className="py-12 px-6 md:p-12">
            <h1 className="font-semibold text-skugray-900 text-4xl mb-12">{translate('me.welcome')} {user.username}!</h1>
            <h1 className="font-semibold text-skugray-900 text-2xl">{translate('me.myContentOn')} skUnity</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-4">
              <div className="bg-skugray-100 py-2 px-4">
                <div className="flex items-center">
                  <h2 className="flex-1 font-semibold text-lg">Addons</h2>
                  <Link to="/manage/addons" className="flex-none font-semibold sku-link">{translate('me.seeAll')} <FontAwesomeIcon icon={faLongArrowAltRight} /></Link>
                </div>
                <div className="py-4">
                  {myAddonsLoading && <div className="w-full text-center"><FontAwesomeIcon className="animate-spin text-3xl text-skugray-500" icon={faSpinner} /></div>}
                  {myAddonsData && myAddons.map((addon) => <Link to={"/manage/addons/"+addon.name} key={addon.id} className="block w-max sku-link font-semibold">{addon.name}</Link>)}
                </div>
              </div>
              <div className="bg-skugray-100 py-2 px-4">
                <div className="flex items-center">
                  <h2 className="flex-1 font-semibold text-lg">{translate('examples')}</h2>
                  <a href="#" className="flex-none font-semibold sku-link">{translate('me.seeAll')} <FontAwesomeIcon icon={faLongArrowAltRight} /></a>
                </div>
                <div className="py-4">
                  <a className="block w-max sku-link font-semibold" href="#">Amount of Items (Skript)</a>
                </div>
              </div>
              <div className="bg-skugray-100 py-2 px-4">
                <div className="flex items-center">
                  <h2 className="flex-1 font-semibold text-lg">{translate('me.parserSaves')}</h2>
                  <a href="#" className="flex-none font-semibold sku-link">{translate('me.seeAll')} <FontAwesomeIcon icon={faLongArrowAltRight} /></a>
                </div>
                <div className="py-4">
                  <a className="block w-max sku-link font-semibold" href="#">HungerGames.sk</a>
                  <a className="block w-max sku-link font-semibold" href="#">Essentials.sk</a>
                  <a className="block w-max sku-link font-semibold" href="#">mySnippets.sk</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default injectIntl(Manage)