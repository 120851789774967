import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { DocsContext } from '../../context/docs'

import _ from 'lodash'

//CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import '../../static/searchInput.scss'

class SearchInput extends Component {

	static contextType = DocsContext

	constructor(props){
		super(props)
    this.inputDiv = React.createRef()
  	this.initialAutoComplete = ['addon:', 'category:', 'id:']
  	this.state = {
  		tags: [],
  		autoComplete: this.initialAutoComplete,
  		typing: '',
  		value: ''
  	}

  	this.onChange = this.onChange.bind(this)
  	this.onKeyDown = this.onKeyDown.bind(this)
  	this.addTag = this.addTag.bind(this)
  	this.removeTag = this.removeTag.bind(this)
  	this.handleTag = this.handleTag.bind(this)
  	this.sendFilter = this.sendFilter.bind(this)
  	this.startParsing = this.startParsing.bind(this)
	}

	parseParam(params){
		if(!params) return
		var categories = []
		var addons = []
    var ids = []
		var value
		params.split('&').map((param) => {
			addons.push(_.filter(_.uniq(_.map(this.context.docs,'addon.name')).sort(), (addon) => addon.toLowerCase() === param.toLowerCase() && true))
			categories.push(_.filter(_.uniq(_.map(this.context.docs,'type')).sort(), (category) => category.toLowerCase() === param.toLowerCase() && true))
      ids.push(_.filter(_.uniq(_.map(this.context.docs,'id')).sort(), (id) => id.toString() === param.toLowerCase() && true))
		})
		categories = _.compact(_.flatten(categories))
		addons = _.compact(_.flatten(addons))
    ids = _.compact(_.flatten(ids))
		if(_.isEmpty(addons) && _.isEmpty(categories)){
			params = params.replace('+', ' ')
			if(params.match(/addon:[^ ]+/gi)) params.match(/addon:[^ ]+[ ]{0,1}/gi).map((addon) => {
				addons.push(_.filter(_.uniq(_.map(this.context.docs,'addon.name')).sort(), (addon2) => addon2.toLowerCase() === addon.substr(6,addon.length).trim().toLowerCase() && true))
				params = params.replace(addon,'')
			})
			if(params.match(/category:[^ ]+/gi)) params.match(/category:[^ ]+[ ]{0,1}/gi).map((category) => {
				categories.push(_.filter(_.uniq(_.map(this.context.docs,'type')).sort(), (category2) => category2.toLowerCase() === category.substr(9,category.length).trim().toLowerCase() && true))
				params = params.replace(category,'')
			})
      if(params.match(/id:[^ ]+/gi)) params.match(/id:[^ ]+[ ]{0,1}/gi).map((id) => {
        ids.push(_.filter(_.uniq(_.map(this.context.docs,'id')).sort(), (id2) => id2.toString() === id.toString().substr(3,id.length).trim().toLowerCase() && true))
        params = params.replace(id,'')
      })
			categories = _.compact(_.flatten(categories))
			addons = _.compact(_.flatten(addons))
      ids = _.compact(_.flatten(ids))
			value = params
		}
		return {categories, addons, ids, value}
	}

	startParsing(){
    let filter
    if(this.props.match.params.id){
      filter = _.merge(this.parseParam(this.props.match.params.id))
      filter.addons = []
      filter.categories = []
      filter.value = []
    } else {
      filter = _.merge(this.parseParam(this.props.match.params.filter), this.parseParam(this.props.match.params.tag), this.parseParam(this.props.match.params.tag2))
    }
		
		let addons = []
		let categories = []
    let ids = []
		document.getElementById('searchInput').value = filter.value ? filter.value : ''
		if(filter.addons) filter.addons.map((addon) => { addons.push('addon:'+addon) })
		if(filter.categories) filter.categories.map((category) => { categories.push('category:'+category) })
    if(filter.ids) filter.ids.map((id) => { ids.push('id:'+id) })
		this.addTag(_.union(addons, categories, ids))
	}

	componentWillReceiveProps(nextProps){
		if(this.props.docs !== nextProps.docs) this.startParsing()
	}

	componentDidMount(){
		this.startParsing()
	}

	sendFilter(filter){
		this.setState(filter)
		this.props.filter(filter)
	}

	handleTag(e){
		let currentValue = document.getElementById('searchInput').value
		if(this.state.typing === /$/){
			document.getElementById('searchInput').value = currentValue.replace(this.state.typing, ' ' + e.target.innerText)
		} else {
			if(this.state.typing === null) this.state.typing = /$/
			document.getElementById('searchInput').value = currentValue.replace(this.state.typing, e.target.innerText)
		}
		e.target = document.getElementById('searchInput')
		this.onChange(e)
		setTimeout(()=>{document.getElementById('searchInput').focus()},1)
	}

  onFocus(){ // If input is focused unhide the helper
  	document.getElementById('searchHelper').classList.remove('hidden')
    document.getElementById('compressedAddons').classList.add('hidden')
    document.getElementById('compressedCategories').classList.add('hidden')
    document.getElementById('compressedIds').classList.add('hidden')
  }

  onBlur(){ // If input loses focus hide the helper
  	document.getElementById('searchHelper').classList.add('hidden')
  }

  onKeyDown(e){
  	let currentElement = document.querySelector('.searchAutocomplete.active')
  	let nextElement = currentElement && currentElement.nextElementSibling
  	let prevElement = currentElement && currentElement.previousElementSibling
  	if(e.key === 'Enter' && currentElement){ //Enter selected item from search options
  		e.target = currentElement
  		this.handleTag(e)
  	}
  	if(e.key === 'Backspace'){ //Update search options with new value
  		this.onChange(e)
  	}
  	if(e.key === 'ArrowDown'){ //Move to next element in the list
  		if(currentElement){
  			if(nextElement){
  				currentElement.classList.remove('active')
  		  nextElement.classList.add('active')
  			}
  		} else {
  			currentElement = document.querySelector('.searchAutocomplete').classList.add('active')
  		}
  	}
  	if(e.key === 'ArrowUp'){ //Move to the previous element in the list
  		if(currentElement){
  		  if(prevElement){
  		  	currentElement.classList.remove('active')
  		  	prevElement.classList.add('active')
  		  }
  		} else {
  			currentElement = document.querySelector('.searchAutocomplete').classList.add('active')
  		}
  	}
  }

  addTag(tagArr){
  	if(!Array.isArray(tagArr)){
  		tagArr = [tagArr]
  	}
  	let tags = _.union(this.state.tags,tagArr)
  	tagArr.map((tag) => {
  		const regex = new RegExp(tag, "i")
  		document.getElementById('searchInput').value = document.getElementById('searchInput').value.replace(regex,'')
  	})
  	this.sendFilter({...this.state, tags:tags, autoComplete:this.initialAutoComplete, value:document.getElementById('searchInput').value})
  }

  onChange(e){
  	let typing = e.target.value.substr(0,e.target.selectionStart).match(/(\w+)$/gi)
  	let isAddon = e.target.value.substr(0,e.target.selectionStart).match(/addon:(\S+)?$/gi)
  	let isCategory = e.target.value.substr(0,e.target.selectionStart).match(/category:(\S+)?$/gi)
    let isId = e.target.value.substr(0,e.target.selectionStart).match(/id:(\S+)?$/gi)
  	if(isAddon){
  		let addon = isAddon[0].substr(6, isAddon[0].length)
  		let filteredAddons = _.filter(_.uniq(_.map(this.context.docs,'addon.name')).sort(),(name)=> name.toLowerCase().startsWith(addon.toLowerCase()) && true )
  		let value = e.target.value.replace(typing,'')
  		if(!addon) typing = /$/
  		this.sendFilter({...this.state, autoComplete:filteredAddons, typing: typing, value:value})
  		if(addon && filteredAddons[0] && addon.toLowerCase() === filteredAddons[0].toLowerCase()){
  			this.addTag('addon:'+filteredAddons[0])
  		}
  	} else if(isCategory){
  		let category = isCategory[0].substr(9,isCategory[0].length)
  		let filteredCategories = _.filter(_.uniq(_.map(this.context.docs,'type')).sort(),(name)=> name.toLowerCase().startsWith(category.toLowerCase()) && true )
  		this.sendFilter({...this.state, autoComplete:filteredCategories, typing: typing, value:e.target.value})
  		if(category && filteredCategories[0] && category.toLowerCase() === filteredCategories[0].toLowerCase()){
  			this.addTag('category:'+filteredCategories[0])
  		}
  	} else if(isId){
      this.sendFilter({...this.state, autoComplete:[], typing: typing, value:e.target.value})
    } else if(typing) {
  		let filteredInitial = _.filter(this.initialAutoComplete,(name)=> name.toLowerCase().startsWith(typing[0].toLowerCase()) && true )
  		let filteredCategories = _.filter(_.uniq(_.map(this.context.docs,'type')).sort(),(name)=> name.toLowerCase().startsWith(typing[0].toLowerCase()) && true )
  		let filteredAddons = _.filter(_.uniq(_.map(this.context.docs,'addon.name')).sort(),(name)=> name.toLowerCase().startsWith(typing[0].toLowerCase()) && true )
      let filteredIds = _.filter(_.uniq(_.map(this.context.docs,'id')).sort(),(name)=> name.toString().startsWith(typing[0]) && true )
  		let filteredHelp = []
  		_.forEach(filteredInitial, (initial) => { filteredHelp.push(initial) })
  		_.forEach(filteredCategories, (category) => { filteredHelp.push('category:' + category) })
  		_.forEach(filteredAddons, (addon) => { filteredHelp.push('addon:' + addon) })
      _.forEach(filteredIds, (id) => { filteredHelp.push('id:' + id) })
  		if(filteredHelp.length === 0){
  			filteredHelp = this.initialAutoComplete
  			typing = /$/
  		}
  		this.sendFilter({...this.state, autoComplete:filteredHelp, typing: typing, value:e.target.value})
  	} else {
  		this.sendFilter({...this.state, autoComplete:this.initialAutoComplete, typing: typing, value:e.target.value})
  	}
  }

  mouseOver(){
  	if(document.querySelector('.searchAutocomplete.active')) document.querySelector('.searchAutocomplete.active').classList.remove('active')
  }

  removeTag(e){
  	let tags = this.state.tags
  	_.pull(tags, e.target.innerText)
  	document.getElementById('searchInput').focus()
  	this.sendFilter({...this.state, tags:tags})
  }

  showTags(e){
    document.getElementById('compressedAddons').classList.add('hidden')
    document.getElementById('compressedCategories').classList.add('hidden')
    document.getElementById('compressedIds').classList.add('hidden')
    document.getElementById(e.target.getAttribute('for')).classList.toggle('hidden')
  }

	render(){
    let addonTags = _.filter(this.state.tags, (tag) => tag.startsWith('addon:'))
    let categoryTags = _.filter(this.state.tags, (tag) => tag.startsWith('category:'))
    let idTags = _.filter(this.state.tags, (tag) => tag.startsWith('id:'))
		return (
		  <div className="w-full bg-white dark:bg-skugray-700 shadow-md">
				<div className="md:flex items-center px-4 py-2" ref={this.inputDiv}>
					<div className="inline-block md:flex-none text-2xl text-skugray-400 cursor-text pr-4 pb-8 md:pb-0" onClick={(e)=>{document.getElementById('searchInput').focus()}}>
						<FontAwesomeIcon icon={faSearch} />
					</div>
          <input autoComplete="off" id="searchInput" onFocus={this.onFocus} onBlur={this.onBlur} onChange={this.onChange} onKeyDown={this.onKeyDown} className="order-last inline-block md:flex-1 focus:outline-none focus:border-skured-500 pb-8 md:py-2 text-2xl dark:bg-skugray-700 dark:text-skugray-100" placeholder="Search" />
					<div className="md:flex-none relative pb-3 md:pb-0 text-sm md:text-2xl">
						{this.state.tags.length > 4 ? (
                <div>
                  {addonTags.length > 0 && (
                    <span className="searchTag bg-skugray-300 mx-1 md:m-2 p-1 rounded cursor-pointer transition duration-200" hover="toggle" htmlFor="compressedAddons" onClick={this.showTags}>{addonTags.length} addons</span>
                  )}
                  {categoryTags.length > 0 && (
                    <span className="searchTag bg-skugray-300 mx-1 md:m-2 p-1 rounded cursor-pointer transition duration-200" hover="toggle" htmlFor="compressedCategories" onClick={this.showTags}>{categoryTags.length} categories</span>
                  )}
                  {idTags.length > 0 && (
                    <span className="searchTag bg-skugray-300 mx-1 md:m-2 p-1 rounded cursor-pointer transition duration-200" hover="toggle" htmlFor="compressedIds" onClick={this.showTags}>{idTags.length} ids</span>
                  )}
                </div>
              ) : (
              this.state.tags.map((tag)=>
                <span key={tag} className="searchTag bg-skugray-300 mx-1 md:m-2 p-1 rounded cursor-pointer transition duration-200" hover="remove" onClick={this.removeTag}>{tag}</span>
              )
              )
            }
            <div id="compressedAddons" className="absolute p-3 bg-white shadow-md mt-6 rounded-lg hidden" style={{width:"max-content"}}>
              {addonTags.map((tag)=>
                <p key={tag} content="-" className="searchAutocomplete p-1 pr-10 text-sm font-bold text-skugray-600 rounded cursor-pointer" onClick={this.removeTag}>{tag}</p>
              )}
            </div>
            <div id="compressedCategories" className="absolute p-3 bg-white shadow-md mt-6 rounded-lg hidden" style={{width:"max-content"}}>
              {categoryTags.map((tag)=>
                <p key={tag} content="-" className="searchAutocomplete p-1 pr-10 text-sm font-bold text-skugray-600 rounded cursor-pointer" onClick={this.removeTag}>{tag}</p>
              )}
            </div>
            <div id="compressedIds" className="absolute p-3 bg-white shadow-md mt-6 rounded-lg hidden" style={{width:"max-content"}}>
              {idTags.map((tag)=>
                <p key={tag} content="-" className="searchAutocomplete p-1 pr-10 text-sm font-bold text-skugray-600 rounded cursor-pointer" onClick={this.removeTag}>{tag}</p>
              )}
            </div>
					</div>
				</div>
				<div id="searchHelper" className="relative md:absolute p-3 bg-white shadow-md mt-0 md:mt-2 ml-0 md:ml-12 rounded-lg hidden" style={{"minWidth":"15%"}}>
					<p className="p-1 text-xs font-bold text-skugray-600 uppercase">Search options:</p>
					{this.state.autoComplete.map((item)=>
						<p key={item} content="+" className="searchAutocomplete p-1 text-sm font-bold text-skugray-600 rounded cursor-pointer" onMouseOver={this.mouseOver} onMouseDown={this.handleTag}>{item}</p>
					)}
				</div>
			</div>
		)
	}
}

export default withRouter(SearchInput)