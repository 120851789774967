import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { AuthContext } from '../context/auth'

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) =>
      <Component {...props} title={rest.title} />
    } />
  )
}

const AuthRoute = ({ component: Component, ...rest }) => {
  const { user } = useContext(AuthContext)
  return (
    <Route {...rest} render={(props) =>
      user ? <Redirect to='/' /> : <Component {...props} title={rest.title} />
    } />
  )
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user } = useContext(AuthContext)
  return (
    <Route {...rest} render={(props) =>
        user ? <Component {...props} title={rest.title} /> : <Redirect to={{pathname: '/login', state: { from: props.location.pathname }}} />
    } />
  )
}

const AdminRoute = ({ component: Component, ...rest }) => {
  const { user } = useContext(AuthContext)
  return (
    <Route {...rest} render={(props) =>
      user && user.is_admin ? <Component {...props} title={rest.title} /> : <Redirect to={{pathname: '/login', state: { from: props.location.pathname }}} />
    } />
  )
}

export { AuthRoute, PrivateRoute, AdminRoute, PublicRoute }