import React, { useContext, useEffect } from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { injectIntl } from 'react-intl'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import { AuthContext } from '../context/auth'
import { DocsContext } from '../context/docs'

const Navigation = (props) => {
  const { user, darkMode, logout, setDarkMode } = useContext(AuthContext)
  const { docs, getDocs } = useContext(DocsContext)

  console.log(user)

  const translate = (id) => {
    return props.intl.messages[id]
  }

  // LOAD DOCS
  useEffect(() => {
    if(!docs){
      getDocs()
    }
  }, [])

  const navLinks = [
      {name: translate('nav.forums'), href: 'https://forums.skunity.com/'},
      {name: 'Docs', href: '/docs'},
      {name: translate('nav.downloads'), href: '/skript'},
      {name: 'Parser', href: '/parser'},
      {name: translate('nav.donate'), href: '/donate'},
      {name: 'Discord', href: 'https://discord.gg/0l3WlzBPKX7WNjkf', target: '_blank'}
  ]

  if(!user){
    navLinks.push({id: 'Login', name: 'Login', href: {pathname: '/login', state: { from: props.location.pathname }}})
  } else {
    navLinks.push({id: 'User', name: <img className={"rounded-full h-10 w-10 border-2 transition duration-100 hover:border-skugray-100 border-usergroup-" + user.user_group_id} src={user.avatar} alt={user.username} />, href: '#', children: [
      {name: 'My panel', href: '/manage'},
      {name: 'Dark Mode' + darkMode, href: '#', onClick: setDarkMode},
      {name: 'Log out', href: '#', onClick: logout}
    ]})
    if(user.user_group_id === 3) {
      navLinks[_.findKey(navLinks, ['id', 'User'])].children.unshift({name: 'Admin', href: '/admin'})
    }
  }

  const handleDropdown = (e) => {
    let target = e.target.getAttribute('for')
    if(!target) target = e.target.parentNode.getAttribute('for')
    if(!target) target = e.target.parentNode.parentNode.getAttribute('for')
    if(target){
      document.getElementById(target).classList.toggle('hidden')
    }
  }

  const toggleNav = () => {
    document.getElementById('burgerNav').classList.toggle('rotate-90')
    document.getElementById('navContent').classList.toggle('hidden')
  }

  const closeNav = () => {
    document.getElementById('burgerNav').classList.remove('rotate-90')
    document.getElementById('navContent').classList.add('hidden')
  }

  document.addEventListener('mouseup', (e) => {
    let dropdownItems = document.getElementsByClassName('dropdown-item')
    for (var i = 0; i < dropdownItems.length; i++) {
       dropdownItems[i].classList.add("hidden")
    }
    if(document.querySelector('nav')){
      if(!document.querySelector('nav').contains(e.target)) closeNav()
    }
  })

  var navigationBar = (
    <nav className="flex-none flex flex-col lg:flex-row px-10 navbar-expand-lg bg-skugray-900 z-50 items-center">
      <div className="flex-1 flex flex-row items-center w-full lg:w-auto min-h-24">
        <div className="flex-1 px-3 pb-2 text-white text-3xl font-neris leading-none">
          <Link to="/" id="navLogo">skUnity</Link>
        </div>
        <button id="burgerNav" className="flex-initial text-white text-xl leading-none px-3 lg:hidden transition-all duration-200 focus:outline-none transform" type="button" onClick={toggleNav} >
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>
      <div id="navContent" className="flex-grow transition-all duration-200 w-full lg:w-auto lg:max-h-screen lg:flex min-h-24 flex hidden">
        <ul className="flex flex-col lg:flex-row list-none lg:ml-auto w-full lg:w-auto items-center">
        {navLinks.map((navLink) => (
          <li key={navLink.name} className="nav-item" onClick={closeNav}>
          {typeof navLink.href !== 'object' && navLink.href.startsWith("https://") ? (
            <a href={navLink.href} target={navLink.target} className="py-3 lg:py-0 px-3 flex text-md font-semibold leading-snug text-white" onClick={handleDropdown} htmlFor={navLink.children ? "dropdown-" + navLink.id : ""}>
              <span className="mx-2">{navLink.name}</span>
            </a>
	        ) : (
            <Link to={navLink.href} className="py-3 lg:py-0 px-3 flex text-md font-semibold leading-snug text-white" onClick={handleDropdown} htmlFor={navLink.children ? "dropdown-" + navLink.id : ""}>
              <span className="mx-2">{navLink.name}</span>
            </Link>
	        )}
        	{navLink.children && 
            <div id={"dropdown-" + navLink.id} className="dropdown-item absolute hidden">
              { navLink.children.map(child => 
                <div key={child.name} className="nav-item bg-skugray-900 overflow-hidden max-w-full">
                  <Link className="py-3 px-4 flex items-center text-md leading-snug text-white transition-all duration-200 transform hover:translate-x-1" to={child.href} onClick={child.onClick} >{child.name}</Link>
                </div>
              )}
            </div>}
          </li>
          ))}
        </ul>
      </div>
    </nav>
  )

  return navigationBar
}

export default withRouter(injectIntl(Navigation))