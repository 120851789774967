import React, { Component } from 'react'
import Swal from 'sweetalert2'

//CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faCopy } from '@fortawesome/free-solid-svg-icons'

const skunitySwal = Swal.mixin({
  customClass: {
    confirmButton: 'bg-skugray-100 focus:outline-none hover:bg-skugray-200 hover:text-skured-500 text-skugray-800 font-bold py-2 px-4 rounded-l',
    cancelButton: 'bg-skugray-100 focus:outline-none hover:bg-skugray-200 text-skugray-800 font-bold py-2 px-4 rounded-r',
    input: 'bg-skugray-100 border-none shadow-none rounded py-2 px-4 text-skugray-700 focus:outline-none focus:shadow-none focus:border-transparent'
  },
  buttonsStyling: false
})

class DocItem extends Component {
	constructor(props) {
		super(props)
	}

	reportElement(name) {
		skunitySwal.fire({
      title: name,
      width: 'auto',
      html: 'Please provide a brief description to what\'s wrong',
      showCancelButton: true,
      focusClose: false,
      confirmButtonText: 'Report',
      input: 'textarea',
      preConfirm: (reason) => {
      	if(!reason) Swal.showValidationMessage('You cannot leave the field empty')
      }
    }).then((result) => {
    	if(result.isConfirmed){
    		Swal.mixin({
			  	customClass: {
			    	confirmButton: 'bg-skugray-300 focus:outline-none hover:bg-skugray-400 hover:text-skured-500 text-skugray-800 font-bold py-2 px-4 rounded'
			  	},
  				buttonsStyling: false
				}).fire({
    			title: 'Reported',
    			html: 'The element <b>' + name + '</b> has been reported to the Staff. Your description was ' + result.value,
    			icon: 'success'
    		})
    	}
    })
	}

  copyPattern(e, pattern) {
    const lastElement = e.currentTarget.childNodes[e.currentTarget.childNodes.length - 1]
    navigator.clipboard.writeText(pattern)
    lastElement.classList.remove('opacity-0')
    setTimeout(() => {lastElement.classList.add('opacity-0')}, 1000)
  }

	render() {
    return (
			<div id={this.props.doc.id} key={this.props.doc.id} className="container my-10 bg-skugray-100 dark:bg-skugray-700 dark:text-skugray-200 border-l-4 border-skured-500">
      	<div className="text-skugray-900 text-xl font-bold p-4 bg-skugray-100 dark:bg-skugray-900 dark:text-skugray-200" id={this.props.doc.id}>
        	<span>{this.props.doc.name}</span>
          <span className="float-right bg-skugray-900 py-1 px-2 rounded text-skugray-100 font-light text-xs ml-2">{this.props.doc.addon.name}</span>
        	<span className="float-right bg-skugray-900 py-1 px-2 rounded text-skugray-100 font-light text-xs ml-2 capitalize">{this.props.doc.type}</span>
      	</div>
      	<div className="px-4 py-2 font-consolas text-skugray-300 text-sm bg-skugray-900">
        	{this.props.doc.patterns.map((patternToSplit)=>{
        		let pattern = []
        		patternToSplit.split(/%(.*?)%/).map((typeParam, typeKey)=>{
        			if(typeKey%2 === 0) {
	            		typeParam.split(/[\[\]]/).map((optParam, optKey)=>{
	            			if(optKey%2 === 0) {
	            					pattern.push(optParam)
	            			}
	            			if(optKey%2 === 1 && optParam !== '') {
	            				pattern.push(<span className="text-skugray-500">[</span>)
	            				pattern.push(<span className="text-skugray-500">{optParam}</span>)
	            				pattern.push(<span className="text-skugray-500">]</span>)
	            			}
	            		})
        			}
        			if(typeKey%2 === 1) {
        				pattern.push(<span className="text-blue-600">%</span>)
        				pattern.push(<span className="text-blue-400">{typeParam}</span>)
        				pattern.push(<span className="text-blue-600">%</span>)
        			}
        		})
        		return <p className="group" onClick={(e) => this.copyPattern(e, patternToSplit)}>{pattern} <FontAwesomeIcon className="text-skugray-400 opacity-0 group-hover:opacity-100" icon={faCopy} /> <span className="transition duration-100 opacity-0">Copied!</span></p>
        	})}
      	</div>
      	<div className="p-4">
        	{this.props.doc.desc}
      	</div>
      	<div className="p-4 text-lg flex">
        	<span className="flex-1">Examples</span>
        	<span className="flex-none transition duration-200 cursor-pointer hover:text-skured-500" onClick={()=>this.reportElement(this.props.doc.name)}>
        		<FontAwesomeIcon icon={faExclamationTriangle} />
        	</span>
      	</div>
      </div>
    )
	}
}

export default DocItem