import React, { useContext } from 'react'
import Helmet from 'react-helmet'
import { injectIntl } from 'react-intl'
import {  useQuery } from "@apollo/react-hooks"
import { Link } from 'react-router-dom'
import _ from 'lodash'

import { AuthContext } from '../../context/auth'
import ManageSideBar from '../../components/Manage/SideNav'
import DocCounter from '../../components/Manage/DocCounter'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight, faSpinner } from '@fortawesome/free-solid-svg-icons'

import { query } from '../../util/gql'


const MyAddons = (props) => {
  const { user } = useContext(AuthContext)
  const translate = (id) => {
    return props.intl.messages[id]
  }

  const { data: myAddonsData, loading: myAddonsLoading, error: myAddonsError} = useQuery(query.getMyAddons)

  let myAddons
  let myDocs = {
    events: [],
    conditions: [],
    effects: [],
    expressions: [],
    types: []
  }

  if(myAddonsData){
    let dataSorted = _.sortBy(myAddonsData.getMyAddons, [addon => addon.name.toLowerCase()])
    let addonsOwned = _.partition(dataSorted, (addon) => addon.author === user.user_id)
    //let addonsCollab = _.filter(data, {author: user.username})
    //let addonsLeft = _.reject(dataSorted, {author: user.username})
    myAddons = _.concat(addonsOwned[0],addonsOwned[1])

    myDocs.events = _.flatMap(_.map(myAddons, (e) => _.filter(e.docs, {type: 'event'})))
    myDocs.conditions = _.flatMap(_.map(myAddons, (e) => _.filter(e.docs, {type: 'condition'})))
    myDocs.effects = _.flatMap(_.map(myAddons, (e) => _.filter(e.docs, {type: 'effect'})))
    myDocs.expressions = _.flatMap(_.map(myAddons, (e) => _.filter(e.docs, {type: 'expression'})))
    myDocs.types = _.flatMap(_.map(myAddons, (e) => _.filter(e.docs, {type: 'type'})))
  }


  return (
    <div id="MyAddons" className="flex-1 overflow-auto bg-skugray-100">
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content="skUnity Me" />
      </Helmet>
      <div className="flex h-full">
        <ManageSideBar/>
        <div aria-label="breadcrumb" className="flex-1 overflow-y-auto">
          <nav aria-label="breadcrumb" className="fixed w-full px-4 py-2 bg-white shadow-sm">
            <Link to="/manage" className="sku-link">{translate('me.dashboard')}</Link>
            <span className="font-semibold px-2">/</span>
            {translate('me.myAddons')}
          </nav>
          <div className="py-12 px-6 md:px-12 md:py-16">
            <h1 className="font-semibold text-skugray-900 text-2xl">{translate('me.myAddons')}</h1>
            <div className="grid grid-cols-2 lg:grid-cols-5 gap-4 mt-4">
              <DocCounter loading={myAddonsLoading} docs={myDocs} name="Events" />
              <DocCounter loading={myAddonsLoading} docs={myDocs} name="Conditions" />
              <DocCounter loading={myAddonsLoading} docs={myDocs} name="Effects" />
              <DocCounter loading={myAddonsLoading} docs={myDocs} name="Expressions" />
              <DocCounter loading={myAddonsLoading} docs={myDocs} name="Types" />
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-4">
              <div className="bg-white shadow-sm py-2 px-4">
                <div className="flex items-center">
                  <h2 className="flex-1 font-semibold text-lg">Addons</h2>
                  <Link to="/manage/addons" className="flex-none font-semibold sku-link">{translate('me.seeAll')} <FontAwesomeIcon icon={faLongArrowAltRight} /></Link>
                </div>
                <div className="py-4">
                  {myAddonsLoading && <div className="w-full text-center"><FontAwesomeIcon className="animate-spin text-3xl text-skugray-500" icon={faSpinner} /></div>}
                  {myAddonsData && myAddons.map((addon) => <Link to={"/manage/addons/"+addon.name} key={addon.id} className="block w-max sku-link font-semibold">{addon.name}</Link>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default injectIntl(MyAddons)