import React, { useReducer, createContext, useRef, useState } from 'react'
import { monaco, ControlledEditor } from '@monaco-editor/react'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

const PARSE = gql`
  mutation parseScript($content: String!) {
    parseScript(content: $content) {
      results
      linemap
    }
  }
`
const ParserContext = new createContext()

const parserReducer = (state, action) => {
  switch(action.type){
    case 'SET_FILESTATE':
      return {
        ...state,
        fileState: action.payload
      }
    default:
      return state
  }
}

const initialState = {
  storedModels: localStorage.getItem('models') ? JSON.parse(localStorage.getItem('models')) : {},
  currentModels: [],
  fileState: {status: "Failed", errors: "Welcome to the skUnity Parser! Type some code and your parse results will appear here.", commands: 0, functions: 0, variables: 0}
}

const ParserProvider = (props) => {
  const [state, dispatch] = useReducer(parserReducer, initialState)
  const editorRef = useRef()
  const editorInstance = useRef()
  const [models, setModels] = useState([])

  const setFileState = (fileStateData) => {
    dispatch({
      type: 'SET_FILESTATE',
      payload: fileStateData
    })
  }

  const applySmartFix = () => {}

  const capitalizeFirstLetter = (str) => {
    return str.toString().charAt(0).toUpperCase() + str.slice(1)
  }

  const handleEditorDidMount = (_, editor) => {
    editorInstance.current = editor
    editor.updateOptions({
      tabSize: 4,
    })
    monaco.init().then((monaco) => {
      editorRef.current = monaco.editor
      editorRef.current.onDidCreateModel((model) => {
        editorInstance.current.setModel(model)
        setModels(editorRef.current.getModels())
        console.log(editorRef.current.getModels())
        state.storedModels = {...state.storedModels, [model.uri]: {content: model.getValue(), view: editorInstance.current.saveViewState()}}
        localStorage.setItem('models', JSON.stringify(state.storedModels))
      })
      //Dispose automatic generated model
      editorRef.current.getModels().map((model) => {
        model.dispose()
      })
      //Restore stored models, if any, from localStorage
      if(Object.keys(state.storedModels).length > 0){
        const keys = Object.keys(state.storedModels)
          for (const key of keys) {
            createModel(null, key, state.storedModels[key].content)
          }
      } else {
        createModel()
      }
    })
  }

  const handleEditorChange = (ev, value) => {
    let [variableTokens, commandTokens, functionTokens] = new Array(3).fill(0)
    let model = editorInstance.current.getModel()
    editorRef.current.tokenize(model.getValue(), "Skript").map((tokens) => {
      tokens.forEach((token) =>{
        if(token.type === "variable.Skript") variableTokens++
        if(token.type === "local-variable.Skript") variableTokens++
        if(token.type === "command.Skript") commandTokens++
        if(token.type === "function.Skript") functionTokens++
      })
    })
    setFileState({...state.fileState, variables: variableTokens, commands: commandTokens, functions: functionTokens})
    state.storedModels = {...state.storedModels, [model.uri]: {content: value, view: editorInstance.current.saveViewState()}}
    localStorage.setItem('models', JSON.stringify(state.storedModels))
  }

  const modelExists = (uri) => {
    let exists = false
    models.map((model) => {
      if(model.uri === uri) exists = true
    })
    return exists
  }

  //Create a new Tab. (event from button, tabname, content)
  const createModel = (ev = null, name = 'UNTITLED', content = '') => {
    console.log("Creating model " + name)
                                                                                //Sometext( 1 )
    let nameRegex = /(.*?)(\(([0-9]+)\))?(\..*)?$/gi                            //   (1)   (3)
    while(modelExists(name)){
      nameRegex = /(.*?)(\(([0-9]+)\))?(\..*)?$/gi
      let newName = nameRegex.exec(name)
      let num = 1
      if(newName[3] !== undefined) num = parseInt(newName[3]) + 1
      name = newName[1] + '(' + num + ')'
    }
    editorRef.current.createModel(content,'Skript', name)
  }

  //Rename a model (event from doubleclick, model uri [model name])
  const renameModel = (ev, uri = null) => {
    let target = ev.target
    let currentName = target.innerText
    target.setAttribute("contenteditable", "true")
    target.focus()
    target.addEventListener('focusout', (event) => {
      if(modelExists(target.innerText) || target.innerText === ""){
        target.innerText = currentName
        return
      }
      var targetModel
      models.map((model) => {
        if(model.uri === currentName) targetModel = model
      })
      createModel(null, target.innerText, targetModel.getValue())
      closeModel(currentName)
      target.setAttribute("contenteditable", "false")  
    })
    target.onkeypress = (e) => {
      if(e.keyCode === 13){
        e.preventDefault()
        target.setAttribute("contenteditable", "false")
      }
    }
  }

  const closeModel = (uri) => {
    console.log(models)
    console.log('Deleting ' + uri)
    console.log(models)
    models.map((model) => {
      if(model.uri === uri) {
        if(editorInstance.current.getModel().uri === uri) editorInstance.current.setModel(models[0])
        delete state.storedModels[uri]
        localStorage.setItem('models', JSON.stringify(state.storedModels))
        model.dispose()
        setModels(editorRef.current.getModels())
      }
    })
  }

  const setModel = (uri) => {
    models.map((model) => {
      if(model.uri === uri) {
        let currentUri = editorInstance.current.getModel().uri
        console.log("Changing from " + currentUri + " to " + uri)
        if(state.storedModels[currentUri]) state.storedModels[currentUri].view = editorInstance.current.saveViewState()
        localStorage.setItem('models', JSON.stringify(state.storedModels))
        editorInstance.current.setModel(model)
        setModels(editorRef.current.getModels())
        if(state.storedModels[uri]) editorInstance.current.restoreViewState(state.storedModels[uri].view)
      }
    })
  }

  const [parseScript] = useMutation(PARSE, {
    onError: (err) => {
      console.log(err)
    },
    onCompleted: (data) => {
      editorRef.current.getModelMarkers().map((marker) => editorRef.current.setModelMarkers(editorInstance.current.getModel(), marker.owner, [{}]))
      
      let unparsedResult = data.parseScript.results.split("<br>")
      unparsedResult.shift()
      let addons = []
      let parsedData = []
      unparsedResult.map((line, index) => {
        if(line.startsWith("addon")) {
          if(!(line.split(": ")[1] in addons)) {
            addons.push(line.split(": ")[1])
          }
        } else {
          parsedData[index] = updateLine(line, data.parseScript.linemap)
        }
      })
      if(parsedData.length === 0) parsedData[0] = "Successfully parsed with no errors found"
      if(parsedData[0] === "Successfully parsed with no errors found"){
        setFileState({...state.fileState, status: "Success", errors: parsedData[0]})
      } else {
        setFileState({...state.fileState, status: "Failed", errors: parsedData})

      }
    }
  })

  const parse = () => {
    parseScript({ variables: { content: editorInstance.current.getValue() } })
  }

  const getLineFromResult = (result) => {
    const regex = /line (\d*):/gm
    const str = result
    let m

    while ((m = regex.exec(str)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++
      }
      // The result can be accessed through the `m`-variable.
      var returnAble = ""
      m.forEach((match, groupIndex) => {
        returnAble = match
      });
      return returnAble
    }
  }

  const updateLine = (result, linemap) => {
    result = result.replace(/</g, "&lt;").replace(/>/g, "&gt;")

    if(result.startsWith("[skUP:RL]")) return result.replace("[skUP:RL]", "")
    if(result === "<br>Happy New Year from everyone at skUnity!" || result === "Happy New Year from everyone at skUnity!") return result
    if(result === "") return result

    let lineNumber = getLineFromResult(result)
    if(lineNumber === undefined) return result

    result = result.replace(/\[skUP:StartError\](.*?)\[skUP:EndError\]/, <a href="#" onClick={applySmartFix(lineNumber)}>SmartFix</a>)
    result = capitalizeFirstLetter(result)

    editorRef.current.setModelMarkers(editorInstance.current.getModel(), lineNumber, [{
        startLineNumber: parseInt(lineNumber,10),
        startColumn: 1,
        endLineNumber: parseInt(lineNumber,10),
        endColumn: 255,
        message: result,
        severity: "Warning"
    }])

    return { lineNumber: parseInt(lineNumber,10), line: result }
  }

  const handleDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = (e) => {
        let fileRegex = /(.*)\..*$/gi
        let fileName = fileRegex.exec(file.name)
        if(editorInstance.current.getValue().length === 0){
          closeModel(editorInstance.current.getModel().uri)
        }
        createModel(null, fileName[1], e.target.result)
      }
      reader.readAsText(file)
    })
  }

  const highlightLine = (ev) => {
    let line = Number.parseInt(ev.target.getAttribute('for'))
    editorInstance.current.revealLinesInCenter(line, line)
  }

  return (
    <ParserContext.Provider
      value={{ storedModels: state.storedModels, fileState: state.fileState, models, editorInstance, parse, handleDrop, handleEditorDidMount, handleEditorChange, setModel, createModel, closeModel }}
      {...props}
      />
  )
}

export { ParserContext, ParserProvider }