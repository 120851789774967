import { monaco } from '@monaco-editor/react'

const TYPE_LIST = [
  'int(eger)?',
  'number',
  'boolean',
  'text',
  'string',
  '(java)?object',
  'entity',
  'offline ?player',
  'player',
  'time ?span',
  'inventory',
  'item(type|stack)?'
];

const RESERVED_WORDS = [
  'if',
  'loop',
  'while',
  'else',
  'stop',
  'exit',
  'cancel',
  'options',
  'variables',
  'return',
  'continue',
  'new'
];

const BUILT_IN = [
  'target( entity| player| block)?',
  'change value',
  'player',
  'console',
  'victim',
  'attacker',
  'victim',
  'block',
  'now'
];

const LITERALS = [
  'null',
  'false',
  'true'
];

const defineSkript = monaco.init()
  .then((monacoInstance) => {
    monacoInstance.languages.register({ id: 'Skript' })
    const config = {
      autoIndent: true,
      autoClosingOvertype: 'auto',
      surroundingPairs: [
        { open: '{', close: '}' },
        { open: '[', close: ']' },
        { open: '(', close: ')' },
        { open: '<', close: '>' },
        { open: '"', close: '"' },
        { open: '%', close: '%', notIn: ['string', 'comment']  }
      ],
      autoClosingPairs: [
        { open: '{', close: '}' },
        { open: '%', close: '%' },
        { open: '[', close: ']' },
        { open: '(', close: ')' },
        { open: '"', close: '"', notIn: ['string', 'comment'] }
      ],
      onEnterRules: [{
        beforeText: /:$/,
        action: {
          indentAction: monacoInstance.languages.IndentAction.Indent,
        }
      },
      {
        beforeText: /stop$/,
        action: {
          indentAction: monacoInstance.languages.IndentAction.Outdent,
        }
      }]
    }
    monacoInstance.languages.setLanguageConfiguration('Skript', config)
    monacoInstance.languages.setMonarchTokensProvider('Skript', {
      tokenizer: {
        root: [
          {include: "@command"},
          {include: "@comment"},
          {include: "@function"},
          ['\\b[0-9]+(\\.[0-9]+)?\\b', "number"],
          [/event-[^\s(){}#]+/, "event-value"],
          [/arg-[^\s(){}#]+/, "argument"],
          [/expr-[^\s(){}#]+/, "event-value"],
          [/loop-[^\s(){}#]+/, "loop-value"],
          [/^on .+:/, "event"],
          [/{_.*?}/, "local-variable"],
          [/{.{1,}}/, "variable"],
          [/#.*/, "comment"],
          [/(".*?)(%{.{1,}}%)(.*?")/, ["string","variable","string"]],
          [/(".*?)(%(?!%).{1,}%)(.*?")/, ["string","text-variable","string"]],
          [/(".*?)(?!%.*?%)(.*?")/, ["string","string"]],
          // [/(stop|cancel event|exit)/, "stop"],
          [/^(\t| )*(if|else if|else|loop)/, "condition"],
        ],
        type: [
          ['(' + RESERVED_WORDS.join('|') + ')', "keyword"],
          ['(' + LITERALS.join('|') + ')', "literal"],
          ['(' + BUILT_IN.join('|') + ')', "built-in"],
          ['(?:' + TYPE_LIST.join('|') + ')s?', "type"],
        ],
        function: [
          [/^function .+:(?!:)/, "function"]
        ],
        functionname: [
          [/(?<=function )([a-zA-Z0-9-_]+)/, "function-name"]
        ],
        functionparam: [
          [/(?<=[(,])[a-zA-Z0-9]+: [a-zA-Z-_]+(?=[,)])/, "function-param"],
          [/(?<=, )[a-zA-Z0-9]+: [a-zA-Z-_]+(?=[,)])/, "function-param"]
        ],
        functionreturn: [
          [/(?<= :: )[a-zA-Z-_]+(?=:)/, "function-return"]
        ],
        command: [
          [/^(command \/?)([a-zA-Z0-9-_]+)(:)/, ["command", "command-name", "command"]],
          [/^(command \/?)([a-zA-Z0-9-_]+)( [ a-zA-Z0-9-_<>\[\]]+)+(:)/, ["command", "command-name", "command-argument", "command"]]
        ],
        comment: [
          [/#.*/, "comment"]
        ]
      }
    })
    monacoInstance.editor.defineTheme('skUnity', {
      base: 'vs-dark',
      inherit: true,
      rules: [
        { foreground: 'bababa' },
        { token: 'comment', foreground: '797979' },
        { token: 'command', foreground: '8eec6c', fontStyle: 'bold' },
        { token: 'command-argument', foreground: 'fae953', fontStyle: 'bold' },
        { token: 'command-name', foreground: '8eec6c', fontStyle: 'underline' },
        { token: 'function', foreground: '8eec6c', fontStyle: 'bold' },
        { token: 'function-name', foreground: '8eec6c', fontStyle: 'underline' },
        { token: 'function-return', foreground: 'FBB829' },
        { token: 'parameter', foreground: 'ffdd63' },
        { token: 'event', foreground: '8eec6c', fontStyle: 'bold' },
        { token: 'variable', foreground: '71b5ef' },
        { token: 'text-variable', foreground: '71b5ef' },
        { token: 'local-variable', foreground: '71b5ef' },
        { token: 'string', foreground: 'd6c87f' },
        // { token: 'stop', foreground: 'F44336' },
        { token: 'condition', foreground: 'D6DA34' },
        { token: 'event-value', foreground: 'fae953' },
        { token: 'argument', foreground: 'fae953' },
        { token: 'type', foreground: 'efbf46' },
        { token: 'built-in', foreground: '6dd7a9' },
        { token: 'loop-value', foreground: '6dd7a9' },
        { token: 'literal', foreground: 'f32868' },
        { token: 'keyword', foreground: '9e50d0' },
        { token: 'number', foreground: 'f56d21' },
      ]
    });
    monacoInstance.languages.registerCompletionItemProvider('Skript', {
      provideCompletionItems: () => {
        var suggestions = [{
          label: 'cancel event',
          kind: monacoInstance.languages.CompletionItemKind.Text,
          insertText: 'cancel event'
        }, {
          label: 'message',
          kind: monacoInstance.languages.CompletionItemKind.Keyword,
          insertText: 'message "${1:content}"',
          insertTextRules: monacoInstance.languages.CompletionItemInsertTextRule.InsertAsSnippet
        }, {
          label: 'send',
          kind: monacoInstance.languages.CompletionItemKind.Keyword,
          insertText: 'send "${1:content}" to ${0:player}',
          insertTextRules: monacoInstance.languages.CompletionItemInsertTextRule.InsertAsSnippet
        },
        {
          label: 'command',
          kind: monacoInstance.languages.CompletionItemKind.Snippet,
          insertText: [
            'command /${1:name} <${2:argument}>:',
            '\tdescription: ',
            '\tusage: # If omitted this defaults to something similar to how the command was defined above',
            '\tpermission: # If omitted this command can be used by everyone regardless of permissions.',
            '\tpermission message: # If omitted this defaults to "You don\'t have the required permission to use this command"',
            '\texecutable by: # If omitted this command can be executed by players and the console.',
            '\taliases: # a list of aliases of the command which are usually abbreviations of the command',
            '\tcooldown: # the cooldown timespan between command uses',
            '\ttrigger:',
            '\t\t$0'].join('\n'),
          insertTextRules: monacoInstance.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'This is a description about this autocompletion'
        }, {
          label: 'ifelse',
          kind: monacoInstance.languages.CompletionItemKind.Snippet,
          insertText: [
            'if ${1:condition}:',
            '\t$2',
            'else:',
            '\t$0'
          ].join('\n'),
          insertTextRules: monacoInstance.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'If-Else Statement'
        }];
        return { suggestions: suggestions };
      }
    })
})

export default defineSkript
