import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const DocCounter = (props) => {
  const loadingIcon = <FontAwesomeIcon className="animate-spin text-xl text-skured-500" icon={faSpinner} />

  return (
    <div className="col-span-2 md:col-span-1 bg-white shadow-sm py-2 px-4">
      <div className="flex text-center">
        <h2 className="flex-1 font-semibold text-lg">{props.name}</h2>
      </div>
      <div className="py-2 text-skured-500 font-semibold text-center">{props.loading ? loadingIcon : props.docs.[props.name.toLowerCase()].length}</div>
    </div>
  )
}

export default DocCounter