import gql from 'graphql-tag'

const query = {
  getMyAddons: gql`
    {
      getMyAddons {
        id
        name
        hidden
        docs {
          type
        }
      }
    }`,
  getAddonByName: gql`
    query getAddonByName($name: String!) {
      getAddonByName(name: $name){
        id
        name
        author {
          user_id
          username
          avatar
        }
        collaborators {
          user_id
          username
          avatar
        }
        hidden
        docs {
          name
          type
        }
      }
    }`,
  getAllDocs: gql`
    {
      getAllDocs {
        id
        name
        desc
        patterns
        type
        addon {
          name
          hidden
        }
        eventvalues
        changers
        returntype
        is_array
        tags
        reviewed
        plugin
        version
      }
    }`,

}

const mutation = {

}

export {query}